<template>
  <v-col
    cols="12"
    class="ma-0"
    :md="size === 2 ? 12 : size === 3 ? 4 : undefined"
  >
    <v-hover
      v-slot="{ hover }"
      open-delay="100">
      <v-card :elevation="hover ? 12 : 2" class="mx-auto"
      >
        <v-img @click.stop="viewProduct(value)" v-if="value.img"
          :src="require(`@/assets/Package-1600x611px/${value.img}`)"
          height="100%"
        >
        </v-img>
        <v-card-title class="display-2">
          {{value.product_name}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-chip
            class="text-uppercase ma-0"
            color="pink"
            dark
            label
            small
            @click.stop="viewProduct(value)"
          >
            <span class="body-1">{{ $t('MeeTeeMeeNgern1.readMore') }}</span>
          </v-chip>
        </v-card-actions>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
  export default {
    name: 'ProductFeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      viewProduct (value) {
        /* this.$router.push('/Product') */
        console.log(value, 'viewProduct')
        this.$router.push({ name: 'Product', params: { propValue: value } })
      },
    }
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>
